/*
 * @Author: yzr
 * @Date: 2020-11-24 14:24:11
 * @LastEditors: yzr
 * @LastEditTime: 2020-11-24 17:08:50
 */
import Form from './form.vue';
import BatchUpdate from './batch_update.vue';

export { Form, BatchUpdate };

<!--
 * @Author: yzr
 * @Date: 2020-11-24 14:24:20
 * @LastEditors: Nehc
 * @LastEditTime: 2020-12-29 10:23:01
-->

<script>
import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';
import NoticeManageTableForm from './components';
import SalesArea from './sales_area';

const timer = null;
formCreate.component('NoticeManageTableForm', NoticeManageTableForm);
formCreate.component('SalesArea', SalesArea);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      cusName: '',
      cusCode: '',
      cusInfo: {},
      disableComponents: [
        'cusCode',
        'dockingCode',
        'legalRepresent',
        'cusPhone',
        'cusChannelCode',
        'cusChannelName',
        'cusOrgName',
        // 'province',
        // 'city',
        // 'district',
        'provinceCode',
        'cityCode',
        'districtCode',
      ],
      visibleComponents: [
        'dockingCode',
      ],
      salesArea: [
        {
          province: '',
          city: '',
          containFlag: 1,
          district: '',
          provinceCode: '',
          cityCode: '',
          districtCode: '',
        },
      ],
      salesAreasNoContain: [
        {
          province: '',
          city: '',
          containFlag: 0,
          district: '',
          provinceCode: '',
          cityCode: '',
          districtCode: '',
        },
      ],
    };
  },

  async mounted() {
    const formRule = await this.getFormRule('contract-form-area');
    this.rule = formRule;

    formRule.map((v) => {
      const rowData = v;
      if (rowData.field === 'files') {
        rowData.props.showFileList = true;
        rowData.props.uploadType = 'file';
        rowData.props.listType = 'text';
        rowData.props.fileMsg = '只能上传pdf/word/excel/ppt/png/jpg文件';
        rowData.props.accept = '.pdf,.docx,.doc,.xlsx,.xls,.ppt,.pptx,.png,.jpg';
      } else if (rowData.field === 'endTime' || rowData.field === 'startTime') {
        rowData.props = {
          ...rowData.props,
          relation: {
            beginField: 'startTime',
            endField: 'endTime',
          },
        };
      } else if (rowData.field === 'saleCompanyCode') {
        rowData.on = {
          ...rowData.on,
          change: (val) => {
            this.setValue({
              saleCompanyName: rowData.options.filter((a) => a.dictCode === val)[0].dictValue,
            });
          },
        };
      } else if (rowData.field === 'code') {
        rowData.validate = [
          ...rowData.validate,
          {
            trigger: 'blur',
            pattern: '^[a-zA-Z0-9]*$',
            message: '仅能输入英文和数字的组合',
          },
        ];
      } else if (rowData.field === 'id') {
        rowData.visible = !this.formConfig.id;
        rowData.props = {
          ...rowData.props,
          disabled: true,
        };
      }

      this.hiddenFields(!this.formConfig.id, 'id');
      return rowData;
    });

    // 初始化表单
    this.initEditValue();
    const newRule = formRule.map((item) => {
      let obj = item;
      if (item.field === 'cusName') {
        obj = {
          ...item,
          type: 'NoticeManageTableForm',
          props: {
            cusName: this.cusName,
            view: this.formConfig.code === 'view',
          },
          on: {
            setCusName: this.setCusName,
          },
          value: {},
        };
      }

      if (item.field === 'salesAreas') {
        obj = {
          ...item,
          type: 'SalesArea',
          props: {
            areas: this.salesArea,
            edit: false,
            view: this.formConfig.code === 'view',
            containFlag: 1,
          },
          on: {
            setSalesArea: this.setSalesArea,
          },
          value: {},
        };
      }
      if (item.field === 'salesAreasNoContain') {
        obj = {
          ...item,
          type: 'SalesArea',
          props: {
            areas: this.salesAreasNoContain,
            edit: false,
            view: this.formConfig.code === 'view',
            containFlag: 0,
          },
          on: {
            setSalesArea: this.setSalesAreasNoContain,
          },
          value: {},
        };
      }
      return obj;
    });
    this.rule = [];
    this.rule = newRule;

    // 未选择客户则禁用
    // console.log(this.rule);
    this.disableComponents.map((item) => {
      // console.log('item', item);
      this.getRule(item).props.disabled = true;
      return true;
    });

    if (this.formConfig.cusName && this.formConfig.code !== 'view') {
      // if (!this.formConfig.noArea) this.visibleComponents.push('salesCity', 'salesDistrict');
      this.visibleComponents.map((item) => {
        this.getRule(item).props.disabled = false;
        return true;
      });
    }

    this.setSelect(
      'provinceCode',
      '/mdm/mdmRegionController/select',
      { regionLevel: '1' },
      { label: 'regionName', value: 'regionCode' },
    );
    this.getRule('provinceCode').on.change = this.proChange;
  },

  methods: {
    // 选多销售区域
    setSalesArea(val) {
      val.map((v) => {
        const rowData = v;
        rowData.containFlag = 1;
        return rowData;
      });
      this.salesArea = val;
    },
    setSalesAreasNoContain(val) {
      val.map((v) => {
        const rowData = v;
        rowData.containFlag = 0;
        return rowData;
      });
      this.salesAreasNoContain = val;
    },

    getAreas(val) {
      const area = val;
      return area;
    },

    // setCusName
    setCusName(val) {
      this.cusInfo = val;
      this.cusName = val.customerName;
      this.cusCode = val.customerCode;

      // 对接人
      this.getRule('dockingCode').props.disabled = false;
      this.setSelect(
        'dockingCode',
        '/mdm/mdmCustomerMsgController/dockingList',
        { customerCode: val.customerCode },
        { label: 'fullName', value: 'userName' },
        { functionCode: 'select-docking' },
      );

      // 市
      this.setSelect(
        'cityCode',
        '/mdm/mdmRegionController/select',
        { regionLevel: '2', parentCode: val.provinceCode },
        { label: 'regionName', value: 'regionCode' },
      );
      // 区
      this.setSelect(
        'districtCode',
        '/mdm/mdmRegionController/select',
        { regionLevel: '3', parentCode: val.cityCode },
        { label: 'regionName', value: 'regionCode' },
      );
      this.setValue({
        ...val,
        cusCode: val.customerCode,
        cusName: val.customerName,
        legalRepresent: val.legalRepresentative,
        cusPhone: val.customerContact,
        cusOrgName: val.orgName,
        cusOrgCode: val.orgCode,
        cusChannelCode: val.channel,
        cusChannelName: val.channelName,
        provinceCode: val.provinceCode,
        cityCode: val.cityCode,
        districtCode: val.districtCode,
        dockingCode: '',
      });
      const newRule = this.rule;
      // this.rule = [];
      this.rule = newRule;
      this.reload();
    },

    // 省份变更
    proChange(value) {
      this.setValue({});
      this.getRule('cityCode').props.disabled = false;
      this.setSelect(
        'cityCode',
        '/mdm/mdmRegionController/select',
        { regionLevel: '2', parentCode: value },
        { label: 'regionName', value: 'regionCode' },
      );
      this.getRule('cityCode').on.change = this.cityChange;

      const newRule = this.rule;
      this.rule = [];
      this.rule = newRule;
    },

    // 城市变更
    cityChange(value) {
      this.setValue({});
      this.getRule('districtCode').props.disabled = false;
      this.setSelect(
        'districtCode',
        '/mdm/mdmRegionController/select',
        { regionLevel: '3', parentCode: value },
        { label: 'regionName', value: 'regionCode' },
        { functionCode: 'select-org' },
      );

      const newRule = this.rule;
      this.rule = [];
      this.rule = newRule;
    },

    submit() {
      // if (timer) clearTimeout(timer);
      // timer = setTimeout(() => {
      const formData = this.getFormData();
      if (formData) this.submitForm();
      // }, 500);
    },

    // 提交表单
    submitForm() {
      if (!this.cusName) {
        this.$message.error('请选择客户名称');
        return;
      }
      const { edit, noArea } = this.formConfig;
      let requestUrl = '/dms/m/contract/add';
      if (edit) {
        requestUrl = '/dms/m/contract/edit';
      }

      const params = {
        ...this.getFormData(),
        salesAreas: this.salesArea,
        salesAreasNoContain: this.salesAreasNoContain,
        areasHideFlag: 1,
      };

      if (edit) {
        params.id = this.formConfig.id;
        if (!this.cusName) {
          params.cusName = this.formConfig.cusName;
          params.cusCode = this.formConfig.cusCode;
        }
      } else {
        params.cusName = this.cusInfo.customerName;
        params.cusCode = this.cusInfo.customerCode;
        delete params.id;
      }

      if (noArea) params.areasHideFlag = 0;
      request.post(requestUrl, params).then((res) => {
        if (res.code === 200) {
          this.$message.success('操作成功');
          this.$emit('onClose');
          this.$emit('onGetList');
        } else {
          this.$message.error(res.messge);
        }
      });
    },

    // 设置下拉框状态
    setSelect(fields, restful, params, optionsKey, headers) {
      this.getRule(fields).restful = restful;
      this.getRule(fields).refresh = true;
      this.getRule(fields).restfulParams = params;
      this.getRule(fields).optionsKey = optionsKey;
      this.getRule(fields).headers = headers || {};
    },

    // 编辑赋值
    initEditValue() {
      // 无销售区域则隐藏相关控件
      if (this.formConfig.noArea) {
        this.hiddenFields(true, ['salesAreas', 'salesAreasNoContain']);
      }
      const { edit, salesAreas, salesAreasNoContain } = this.formConfig;
      if (!edit) {
        this.resetFields();
      } else {
        this.getContractInfo(this.formConfig.id);

        // 对接人
        this.setSelect(
          'dockingCode',
          '/mdm/mdmCustomerMsgController/dockingList',
          { customerCode: this.formConfig.cusCode },
          { label: 'fullName', value: 'userName' },
          { functionCode: 'select-docking' },
        );
        if (salesAreas && salesAreas.length > 0) {
          this.setValue({
            salesProvince: salesAreas[0].provinceCode,
            salesCity: salesAreas[0].cityCode,
            salesDistrict: salesAreas[0].districtCode,
          });
        }
        if (salesAreasNoContain && salesAreasNoContain.length > 0) {
          this.setValue({
            salesProvince: salesAreasNoContain[0].provinceCode,
            salesCity: salesAreasNoContain[0].cityCode,
            salesDistrict: salesAreasNoContain[0].districtCode,
          });
        }
        this.cusName = this.formConfig.cusName;
        this.cusCode = this.formConfig.cusCode;
      }
    },

    // 通过id查询合同信息
    getContractInfo(id) {
      request.get(`/dms/m/contract/findById/${id}`).then((res) => {
        const data = res.result;
        // 市
        this.setSelect(
          'cityCode',
          '/mdm/mdmRegionController/select',
          { regionLevel: '2', parentCode: res.result.provinceCode },
          { label: 'regionName', value: 'regionCode' },
        );
        // 区
        this.setSelect(
          'districtCode',
          '/mdm/mdmRegionController/select',
          { regionLevel: '3', parentCode: res.result.cityCode },
          { label: 'regionName', value: 'regionCode' },
        );

        const files = [];
        data.type = `${data.type}`;
        res.result.files.map((item) => {
          files.push({
            fileName: item.fileName,
            url: item.url,
            urlPath: item.urlPath,
            urlPathPrefix: item.urlPathPrefix,
            objectName: item.objectName,
          });
          return files;
        });
        if (res.result.salesAreas && res.result.salesAreas.length) {
          const area = res.result.salesAreas;
          this.salesArea = area;
          if (this.getRule('salesAreas')) {
            this.getRule('salesAreas').props.areas = area;
            this.getRule('salesAreas').props.edit = true;
          }
        }
        if (res.result.salesAreasNoContain && res.result.salesAreasNoContain.length) {
          const area = res.result.salesAreasNoContain;
          this.salesAreasNoContain = area;
          if (this.getRule('salesAreasNoContain')) {
            this.getRule('salesAreasNoContain').props.areas = area;
            this.getRule('salesAreasNoContain').props.edit = true;
          }
        }
        this.setValue({
          ...res.result,
          files,
        });
        return data;
      });
    },
  },
};
</script>

<!--
 * @Author: yzr
 * @Date: 2020-11-30 09:47:12
 * @LastEditors: Nehc
 * @LastEditTime: 2020-12-23 11:14:38
-->
<template>
  <div>
    <el-row v-for="(area, index) in salesArea" :key="index">
      <el-form label-position="right" label-width="80px" :model="area">
        <!-- 省 -->
        <el-col :span="4">
          <el-form-item label-width="0" prop="countryCode">
            <el-select
              v-model="area.countryCode"
              @focus="()=>getCountry(index)"
              @change="(val) => setCountry(val , index ,countryOption)"
              placeholder="请选择国家"
              :disabled='view'
            >
              <el-option
                v-for="item in countryOption"
                :key="item.regionCode"
                :label="item.regionName"
                :value="item.regionCode"
              >
              </el-option>
            </el-select>
            <!-- <el-input v-model="area.provinceCode"/> -->
          </el-form-item>
        </el-col>
        <!-- 省 -->
        <el-col :span="4" :offset="1">
          <el-form-item label-width="0" prop="provinceCode">
            <el-select
              v-model="area.provinceCode"
              @focus="()=>getProvince(index)"
              @change="(val) => setProvince(val , index ,provinceOption)"
              placeholder="请选择省"
              :disabled='!area.countryCode ||view'
            >
              <el-option
                v-for="item in provinceOption"
                :key="item.regionCode"
                :label="item.regionName"
                :value="item.regionCode"
              >
              </el-option>
            </el-select>
            <!-- <el-input v-model="area.provinceCode"/> -->
          </el-form-item>
        </el-col>

        <!-- 市 -->
        <el-col :span="4" :offset="1">
          <el-form-item label-width="0">
            <el-select
              v-model="area.cityCode"
              @focus="()=>getCity(index)"
              @change="(val) => setCity(val , index ,cityOption)"
              placeholder="请选择市"
              :disabled='!area.provinceCode || view'
            >
              <el-option
                v-for="item in cityOption"
                :key="item.regionCode"
                :label="item.regionName"
                :value="item.regionCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <!-- 区县 -->
        <el-col :span="4" :offset="1">
          <el-form-item label-width="0">
            <el-select
              v-model="area.districtCode"
              @focus="()=>getDis(index)"
              @change="(val) => setDis(val , index ,districtOption)"
              placeholder="请选择区/县"
              :disabled='!area.cityCode || view'
            >
              <el-option
                v-for="item in districtOption"
                :key="item.regionCode"
                :label="item.regionName"
                :value="item.regionCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <!-- 删除 -->
        <el-col :span="4" :offset="1">
          <el-button
            type="danger"
            icon="el-icon-delete"
            v-if="!view"
            circle
            @click="delArea(index)"
          ></el-button>
        </el-col>
      </el-form>
    </el-row>
    <el-row>
      <el-button v-if="!view" type="primary" icon="el-icon-plus" @click="addArea">新增销售范围</el-button>
    </el-row>
  </div>
</template>

<script>
import request from '@/found/utils/request';

export default {
  name: 'AreaSelect',
  props: {
    areas: Array,
    edit: Boolean,
    view: Boolean,
    containFlag: Number,
  },
  data() {
    return {
      requestUrl: '/mdm/mdmRegionController/select',
      salesArea: [
      ],
      countryOption: [
        {
          regionCode: '0', regionName: '中国',
        },
      ],
      provinceOption: [],
      cityOption: [],
      districtOption: [],
      currentPro: '',
      currentCity: '',
      currentDis: '',
      rules: {
        provinceCode: [{
          validator: (rule, value, callback) => {
            callback();
          },
          trigger: 'change',
        },
        ],
      },
    };
  },
  mounted() {
  },
  watch: {
    areas: {
      deep: true,
      immediate: true,
      handler(v) {
        this.salesArea = [];
        const { countryOption } = this;
        const provinceOption = [];
        const cityOption = [];
        const districtOption = [];

        if (!v) return;
        v.forEach((val) => {
          const rowData = val;
          if (countryOption.filter((a) => a.regionCode === val.countryCode).length === 0) {
            countryOption.push({ regionCode: val.countryCode, regionName: val.country });
          }
          if (provinceOption.filter((a) => a.regionCode === val.provinceCode).length === 0) {
            provinceOption.push({ regionCode: val.provinceCode, regionName: val.province });
          }
          if (cityOption.filter((a) => a.regionCode === val.cityCode).length === 0) {
            cityOption.push({ regionCode: val.cityCode, regionName: val.city });
          }
          if (districtOption.filter((a) => a.regionCode === val.districtCode).length === 0) {
            districtOption.push({ regionCode: val.districtCode, regionName: val.district });
          }
          rowData.countryCode = rowData.countryCode || '0';
          rowData.country = rowData.country || '中国';
          this.salesArea.push(JSON.parse(JSON.stringify(rowData)));
        });
        this.countryOption = countryOption;
        this.provinceOption = provinceOption;
        this.cityOption = cityOption;
        this.districtOption = districtOption;
      },
    },
  },

  methods: {

    // 国
    getCountry() {
      this.countryOption = [];
      const param = {
        regionLevel: '0',
      };
      request.post(this.requestUrl, param).then((res) => {
        this.countryOption = res.result;
      });
    },

    setCountry(val, index, option) {
      this.salesArea[index].country = option.filter((v) => v.regionCode === val)[0].regionName;
      this.salesArea[index].province = '';
      this.salesArea[index].provinceCode = '';
      this.salesArea[index].city = '';
      this.salesArea[index].cityCode = '';
      this.salesArea[index].district = '';
      this.salesArea[index].districtCode = '';
      this.$emit('setSalesArea', this.salesArea);
    },
    // 省
    getProvince(index) {
      this.provinceOption = [];
      const param = {
        regionLevel: '1',
        parentCode: this.salesArea[index].countryCode,
      };
      request.post(this.requestUrl, param).then((res) => {
        this.provinceOption = res.result;
      });
    },

    setProvince(val, index, option) {
      this.salesArea[index].province = option.filter((v) => v.regionCode === val)[0].regionName;
      this.salesArea[index].city = '';
      this.salesArea[index].cityCode = '';
      this.salesArea[index].district = '';
      this.salesArea[index].districtCode = '';
      this.$emit('setSalesArea', this.salesArea);
    },

    // 市
    getCity(index) {
      this.cityOption = [];
      const param = {
        regionLevel: '2',
        parentCode: this.salesArea[index].provinceCode,
      };
      request.post(this.requestUrl, param).then((res) => {
        this.cityOption = res.result;
      });
    },
    setCity(val, index, option) {
      this.salesArea[index].city = option.filter((v) => v.regionCode === val)[0].regionName;
      this.salesArea[index].district = '';
      this.salesArea[index].districtCode = '';
      this.$emit('setSalesArea', this.salesArea);
    },

    // 区
    getDis(index) {
      this.districtOption = [];
      const param = {
        regionLevel: '3',
        parentCode: this.salesArea[index].cityCode,
      };
      request.post(this.requestUrl, param).then((res) => {
        this.districtOption = res.result;
      });
    },

    setDis(val, index, option) {
      this.salesArea[index].district = option.filter((v) => v.regionCode === val)[0].regionName;
      this.$emit('setSalesArea', this.salesArea);
    },

    addArea() {
      this.salesArea.push({
        countryCode: '0',
        country: '中国',
        containFlag: this.containFlag,
        province: '',
        city: '',
        district: '',
        provinceCode: '',
        cityCode: '',
        districtCode: '',
      });
      this.$emit('setSalesArea', this.salesArea);
    },

    delArea(index) {
      this.salesArea.splice(index, 1);
      this.$emit('setSalesArea', this.salesArea);
    },
  },
};
</script>

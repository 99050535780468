<!--
 * @Author: yzr
 * @Date: 2020-11-24 10:02:48
 * @LastEditors: yzr
 * @LastEditTime: 2020-12-09 17:29:18
-->
<script>
import TablePage from '@/found/components/table_page';
// import Modal from '@/found/components/modal';
// import configs from './data';
import { Form, BatchUpdate } from '../form';

export default {
  extends: TablePage,
  components: {
    // Modal,
    Form,
    BatchUpdate,
  },
  data() {
    return {
      // requestUrl: '/dms/m/contract/list',
      // configs,
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Modal',
      },
    };
  },
  async mounted() {
    const columns = await this.getConfigList('dms-contract-list');
    columns.map((v) => {
      const rowData = v;
      if (rowData.field === 'createDate' || rowData.field === 'updateDate') {
        rowData.formatter = ({ row }) => `${row.createDate} ${row.createDateSecond}`;
      }
      return rowData;
    });
    this.configs.columns = columns;
  },
  methods: {
    modalClick({ val, row }) {
      this.formConfig = {
        edit: false,
        noArea: false,
      };
      this.modalConfig.width = '';
      this.modalConfig.height = '';
      this.modalConfig.type = 'full';
      this.formName = 'Form';
      if (val.code === 'edit') {
        this.modalConfig.title = '编辑';
        this.formConfig = {
          ...val,
          ...row,
          edit: true,
        };
        if (!row.areasHideFlag) this.formConfig.noArea = true;
      } else if (val.code === 'view') {
        this.modalConfig.title = '查看';
        this.formConfig = {
          ...val,
          ...row,
          edit: true,
          code: val.code,
        };
        if (!row.areasHideFlag) this.formConfig.noArea = true;
      } else if (val.code === 'batchUpdate') {
        if (this.selection.length === 0) {
          this.$message('请选择一条数据');
          return false;
        }
        this.modalConfig.title = '编辑结束时间';
        this.modalConfig.width = '500px';
        this.modalConfig.height = '150px';
        this.modalConfig.type = 'modal';
        this.formName = 'BatchUpdate';
        this.formConfig.ids = this.selection;
        this.openModal();
        return false;
      } else {
        this.modalConfig.title = '新增';
      }

      if (val.code === 'contract-add-noarea') {
        this.formConfig.noArea = true;
      }
      if (val.code !== 'delete') this.openFull();
    },
  },
};
</script>

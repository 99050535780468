<!--
 * @Author: yzr
 * @Date: 2020-11-24 14:24:20
 * @LastEditors: Nehc
 * @LastEditTime: 2020-12-29 10:23:01
-->

<script>
import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
    };
  },

  async mounted() {
    this.getFormRule('dms_contract_endtime');
  },

  methods: {

    submit() {
      this.submitForm();
    },

    // 提交表单
    submitForm() {
      const requestUrl = '/dms/m/contract/updateEndTimeByIds';

      const params = {
        ...this.getFormData(),
        ids: this.formConfig.ids,
      };
      request.post(requestUrl, params).then((res) => {
        if (res.code === 200) {
          this.$message.success('操作成功');
          this.$emit('onClose');
          this.$emit('onGetList');
        } else {
          this.$message.error(res.messge);
        }
      });
    },

  },
};
</script>

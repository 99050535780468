var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.salesArea, function (area, index) {
        return _c(
          "el-row",
          { key: index },
          [
            _c(
              "el-form",
              {
                attrs: {
                  "label-position": "right",
                  "label-width": "80px",
                  model: area,
                },
              },
              [
                _c(
                  "el-col",
                  { attrs: { span: 4 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { "label-width": "0", prop: "countryCode" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择国家",
                              disabled: _vm.view,
                            },
                            on: {
                              focus: function () {
                                return _vm.getCountry(index)
                              },
                              change: function (val) {
                                return _vm.setCountry(
                                  val,
                                  index,
                                  _vm.countryOption
                                )
                              },
                            },
                            model: {
                              value: area.countryCode,
                              callback: function ($$v) {
                                _vm.$set(area, "countryCode", $$v)
                              },
                              expression: "area.countryCode",
                            },
                          },
                          _vm._l(_vm.countryOption, function (item) {
                            return _c("el-option", {
                              key: item.regionCode,
                              attrs: {
                                label: item.regionName,
                                value: item.regionCode,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 4, offset: 1 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { "label-width": "0", prop: "provinceCode" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择省",
                              disabled: !area.countryCode || _vm.view,
                            },
                            on: {
                              focus: function () {
                                return _vm.getProvince(index)
                              },
                              change: function (val) {
                                return _vm.setProvince(
                                  val,
                                  index,
                                  _vm.provinceOption
                                )
                              },
                            },
                            model: {
                              value: area.provinceCode,
                              callback: function ($$v) {
                                _vm.$set(area, "provinceCode", $$v)
                              },
                              expression: "area.provinceCode",
                            },
                          },
                          _vm._l(_vm.provinceOption, function (item) {
                            return _c("el-option", {
                              key: item.regionCode,
                              attrs: {
                                label: item.regionName,
                                value: item.regionCode,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 4, offset: 1 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { "label-width": "0" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择市",
                              disabled: !area.provinceCode || _vm.view,
                            },
                            on: {
                              focus: function () {
                                return _vm.getCity(index)
                              },
                              change: function (val) {
                                return _vm.setCity(val, index, _vm.cityOption)
                              },
                            },
                            model: {
                              value: area.cityCode,
                              callback: function ($$v) {
                                _vm.$set(area, "cityCode", $$v)
                              },
                              expression: "area.cityCode",
                            },
                          },
                          _vm._l(_vm.cityOption, function (item) {
                            return _c("el-option", {
                              key: item.regionCode,
                              attrs: {
                                label: item.regionName,
                                value: item.regionCode,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 4, offset: 1 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { "label-width": "0" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择区/县",
                              disabled: !area.cityCode || _vm.view,
                            },
                            on: {
                              focus: function () {
                                return _vm.getDis(index)
                              },
                              change: function (val) {
                                return _vm.setDis(
                                  val,
                                  index,
                                  _vm.districtOption
                                )
                              },
                            },
                            model: {
                              value: area.districtCode,
                              callback: function ($$v) {
                                _vm.$set(area, "districtCode", $$v)
                              },
                              expression: "area.districtCode",
                            },
                          },
                          _vm._l(_vm.districtOption, function (item) {
                            return _c("el-option", {
                              key: item.regionCode,
                              attrs: {
                                label: item.regionName,
                                value: item.regionCode,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 4, offset: 1 } },
                  [
                    !_vm.view
                      ? _c("el-button", {
                          attrs: {
                            type: "danger",
                            icon: "el-icon-delete",
                            circle: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.delArea(index)
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
      _c(
        "el-row",
        [
          !_vm.view
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: { click: _vm.addArea },
                },
                [_vm._v("新增销售范围")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }